import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ImagePopup = ({
  buttonPopup,
  handleClose,
  handleImageChange,
  imagePreviewUrl,
  isLoading,
  error,
  searchSuccessful,
  uploadImage,
}) => (
  <Dialog
    open={buttonPopup}
    onClose={handleClose}
    style={{}}
    PaperProps={{
      style: {
        width: "60%", // Adjust as needed
        height: "40%", // Adjust as needed
        maxWidth: "none",
        borderRadius: "30px",
        backgroundColor: "transparent",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(9px)",
        border: "1px solid rgba(0, 0, 0, 0.18)",
      },
    }}
  >
    <DialogTitle
      style={{
        color: "white",
        fontFamily: "Futura",
        fontSize: "34px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      Upload Image
    </DialogTitle>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        borderRadius: "30px",
        margin: "20px",
      }}
    >
      <input
        type="file"
        onChange={handleImageChange}
        style={{
          display: "block",
          padding: "45px",
          border: "none",
          backgroundColor: "white",
          borderRadius: "30px",
        }}
      />
      {imagePreviewUrl && (
        <img
          src={imagePreviewUrl}
          alt="Preview"
          style={{ maxWidth: "100px", maxHeight: "100px", margin: "5px" }}
        />
      )}
      {isLoading && (
        <p style={{ textAlign: "center", color: "white" }}>Loading...</p>
      )}
      {error && <p className="text-danger">{error}</p>}
      {searchSuccessful && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <CheckCircleIcon style={{ color: "green" }} />
          <span style={{ marginLeft: "10px", color: "white" }}>Detected</span>
        </div>
      )}
    </div>
    <Button
      style={{
        fontFamily: "Futura",
        textTransform: "none",
        color: "white",
        fontSize: "30px",
        padding: "10px 20px",
        border: "none",
        borderRadius: "0px",
        cursor: "pointer",
      }}
      onClick={uploadImage}
      disabled={isLoading}
    >
      Search
    </Button>
  </Dialog>
);

export default ImagePopup;
