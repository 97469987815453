import React, { useState, useEffect } from "react";
import "./Navbar.css";
import "../App.css";
import logo from "../Assets/logo6.png"; // Update the path to your logo file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { useCart } from "./CartContext"; // Ensure this path is correct
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";

function Navbar({ signOut, user }) {
  const [scrolled, setScrolled] = useState(false);
  const { cartItems } = useCart();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isAccountPopupOpen, setIsAccountPopupOpen] = useState(false);

  const handleCartIconClick = () => {
    setIsCartOpen(!isCartOpen);
  };
  const handleUserIconClick = () => {
    setIsAccountPopupOpen(true);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0.2) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  return (
    <>
      <nav className={navbarClasses.join(" ")}>
        <div className="navbar-content">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" className="navLogo" />
          </a>
          <div onClick={handleCartIconClick} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon
              icon={faShoppingCart}
              size="2x"
              className="cartIcon"
            />
            {cartItems.length > 0 && (
              <span className="cart-item-count">{cartItems.length}</span>
            )}
          </div>
          {user && (
            // <div className="navbar-user-info" onClick={signOut}>
            <div className="navbar-user-info" onClick={handleUserIconClick}>
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                className="logoutIcon"
              />
            </div>
          )}
        </div>
      </nav>

      <Dialog
        open={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        PaperProps={{
          style: {
            width: "60%", // Adjust as needed
            height: "40%", // Adjust as needed
            maxWidth: "none",
            borderRadius: "30px",
            backgroundColor: "transparent",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(9px)",
            border: "1px solid rgba(0, 0, 0, 0.18)",
          },
        }}
      >
        <DialogTitle
          style={{
            color: "white",
            fontFamily: "Futura",
            fontSize: "34px ",
            textAlign: "center",
            borderRadius: "0px",
            backgroundColor: "transparent",
          }}
        >
          Cart
        </DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            borderRadius: "30px",
            margin: "20px",
          }}
        >
          <List>
            {cartItems.map((item, index) => (
              <ListItem
                style={{
                  color: "white",
                  fontFamily: "Futura",
                  textAlign: "center",
                  borderRadius: "0px",
                  padding: "5px",
                }}
                key={index}
              >
                <ListItemText
                  primary={` Product : ${item.color} ${item.type}  - Quantity: ${item.quantity}`}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "28px",
                      fontFamily: "Futura",
                      color: "white",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <Button
          style={{
            fontFamily: "Futura",
            textTransform: "none",
            color: "white",
            fontSize: "34px",
            padding: "0",

            border: "none",
            borderRadius: "0px",
            cursor: "pointer",
            backgroundColor: "transparent",
          }}
        >
          Checkout
        </Button>
      </Dialog>
      <Dialog
        open={isAccountPopupOpen}
        onClose={() => setIsAccountPopupOpen(false)}
        PaperProps={{
          style: {
            width: "60%", // Adjust as needed
            height: "40%", // Adjust as needed
            maxWidth: "none",
            borderRadius: "30px",
            backgroundColor: "transparent",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(9px)",
            border: "1px solid rgba(0, 0, 0, 0.18)",
          },
        }}
      >
        <DialogTitle
          style={{
            color: "white",
            fontFamily: "Futura",
            fontSize: "34px ",
            textAlign: "center",
            borderRadius: "0px",
            backgroundColor: "transparent",
          }}
        >
          User Profile
        </DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            borderRadius: "30px",
            margin: "20px",
          }}
        >
          <a
            className="Welcome"
            style={{ marginBottom: "20px", textAlign: "center" }}
          >
            Welcome User,
            <br /> {/* Break line for new line */}
            {user.username}
          </a>
          {/*<a style={{ textAlign: "center" }}>{user.email}</a>*/}
        </div>
        <Button
          onClick={signOut}
          style={{
            fontFamily: "Futura",
            textTransform: "none",
            color: "white",
            fontSize: "30px",
            padding: "10px 20px",
            border: "none",
            borderRadius: "0px",
            cursor: "pointer",
          }}
        >
          Logout
        </Button>
      </Dialog>

      <div className="navbar-placeholder"></div>
    </>
  );
}

export default Navbar;
