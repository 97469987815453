import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import Navbar from "../Components/Navbar";
import ImagePopup from "../Components/ImagePopup";
import { useCart } from "../Components/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [internalSearchQuery, setInternalSearchQuery] = useState("");
  const [activeSearchQuery, setActiveSearchQuery] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [error, setError] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSuccessful, setSearchSuccessful] = useState(false);
  const searchSuccessTimeoutRef = useRef();
  const { addToCart } = useCart();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreviewUrl(URL.createObjectURL(file));
      setError(null);
      setSearchResults([]);
    }
  };

  const handleClose = () => {
    setButtonPopup(false);
    setSelectedImage(null);
    setImagePreviewUrl("");
    setSearchSuccessful(false);
    setError(null);
    // Clear timeout if set
    if (searchSuccessTimeoutRef.current) {
      clearTimeout(searchSuccessTimeoutRef.current);
    }
  };

  const handleSearchInputChange = (e) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    setActiveSearchQuery(newSearchQuery); // Use the manual search query for filtering
    setInternalSearchQuery(""); // Clear the internal search query
  };

  const performImageSearch = (detectedColor, detectedClothingItem) => {
    const newInternalSearchQuery = `${detectedColor.toLowerCase()} ${detectedClothingItem.toLowerCase()}`;
    setInternalSearchQuery(newInternalSearchQuery);
    setActiveSearchQuery(newInternalSearchQuery); // Use the image search query for filtering
    setSearchQuery(""); // Optionally clear the manual search bar
  };

  async function uploadImage() {
    setIsLoading(true);
    setError(null);
    setSearchResults([]);

    if (!selectedImage) {
      alert("Please select an image first.");
      setIsLoading(false);
      return;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    };

    try {
      const base64Image = await convertToBase64(selectedImage);
      const extractLabelResponse = await fetch(
        "https://5zhz56zsck.execute-api.eu-west-1.amazonaws.com/fix/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ body: base64Image }),
        },
      );

      const extractedData = await extractLabelResponse.json();
      const parsedBody = JSON.parse(extractedData.body);
      const detectedClothingItem = parsedBody.detected_clothing_item;
      const dominantColors = parsedBody.dominant_colors;

      if (dominantColors.length > 0) {
        const dominantColor = dominantColors;
        const combinedSearchQuery = `${dominantColor} ${detectedClothingItem}`;
        // setInternalSearchQuery(combinedSearchQuery);
        performImageSearch(dominantColor, detectedClothingItem);

        // setSearchQuery(combinedSearchQuery);

        const searchResponse = await fetch(
          "https://5zhz56zsck.execute-api.eu-west-1.amazonaws.com/fix/",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              label_name: detectedClothingItem,
              color: dominantColor,
            }),
          },
        );

        if (searchResponse.ok) {
          const searchResultsData = await searchResponse.json();
          const parsedSearchResults = JSON.parse(searchResultsData.body);
          setSearchResults(parsedSearchResults.clothes);
          const combinedSearchQuery = `${dominantColor} ${detectedClothingItem}`;
          // setSearchQuery(combinedSearchQuery);
          setInternalSearchQuery(combinedSearchQuery);
          setSearchSuccessful(true);

          // Auto-close the popup after 3 seconds
          setTimeout(() => {
            handleClose(); // Make sure handleClose clears the state and the timeout
          }, 800);
        } else {
          setError("Error fetching search results");
          setSearchSuccessful(false);
        }
      } else {
        setError("No dominant colors detected");
      }
    } catch (error) {
      console.error("Error processing image:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const apiGatewayUrl =
      "https://5zhz56zsck.execute-api.eu-west-1.amazonaws.com/stage3/FetchImage";

    fetch(apiGatewayUrl)
      .then((res) => res.json())
      .then((data) => {
        setItems(data.clothes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const filteredItems = items.filter((item) => {
    const itemDescription = `${item.color.toLowerCase()} ${item.type.toLowerCase()}`;
    return itemDescription.includes(activeSearchQuery.toLowerCase());
  });

  const maxImageHeight = 200;
  const maxImageWidth = 200;

  return (
    <div className="App">
      {/*<Navbar />*/}

      <div className="products">
        <h1>- Products -</h1>
      </div>

      <div className="search-bar">
        <div>
          <input
            className="search-input"
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <button className="search-icon" onClick={() => setButtonPopup(true)}>
            {/* Make sure FontAwesomeIcon is imported if you're using it */}
            <FontAwesomeIcon icon={faCamera} />
          </button>
        </div>

        {/* Use the ImagePopup component and pass necessary props */}
        <ImagePopup
          buttonPopup={buttonPopup}
          handleClose={handleClose}
          handleImageChange={handleImageChange}
          imagePreviewUrl={imagePreviewUrl}
          isLoading={isLoading}
          error={error}
          searchSuccessful={searchSuccessful}
          uploadImage={uploadImage}
        />
      </div>

      <div className="container-fluid sm">
        <div className="row">
          {filteredItems.map((item, index) => (
            <div key={index} className="col-md-3 mb-3">
              <div
                className="card text-center"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="card-body">
                  <img
                    src={item.imageUrl}
                    alt={`Image ${index}`}
                    style={{
                      maxHeight: `${maxImageHeight}px`,
                      maxWidth: `${maxImageWidth}px`,
                      width: "auto",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                </div>

                <p
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginTop: "1rem",
                  }}
                >
                  Type: {item.type}
                </p>
                <p style={{ color: "white", fontSize: "20px" }}>
                  Colour: {item.color}
                </p>
                {/*<p style={{ color: "white", fontSize: "20px" }}>*/}
                {/*  Id: {item.id}*/}
                {/*</p>*/}
                <button
                  className="AddToCartButton"
                  onClick={() => addToCart(item)}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Home;
