import React from "react";
import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import { withAuthenticator } from "@aws-amplify/ui-react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { CartProvider } from "./Components/CartContext";

Amplify.configure(awsExports);

function App({ signOut, user }) {
  return (
    <CartProvider>
      <Router>
        <div className="background">
          <Navbar signOut={signOut} user={user} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>{" "}
        </div>{" "}
      </Router>{" "}
    </CartProvider>
  );
}

export default withAuthenticator(App, Navbar, { includeGreetings: true });
